
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import MultiSelectList from "@/components/MultiSelectList.vue";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { RegionLookupItem } from "@/model/RegionLookupItem";
    import { ModuleSearchParameters } from "@/model/ModuleSearchParameters";
    import { IModule } from "@/model/Module";
    import { ISite } from "@/model/Site";
    import { IBuyerSupplier, BuyerSupplier } from "@/model/BuyerSupplier";
    import { IBuyerSupplierModule, BuyerSupplierModule} from "@/model/BuyerSupplierModule";
    import { IRegionSupplier, RegionSupplier } from "@/model/RegionSupplier";
    import { RegionSupplierSearchParameters } from "@/model/RegionSupplierSearchParameters";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";
    import { SiteSearchParameters } from "@/model/SiteSearchParameters";
    import TickOrCross from "@/components/TickOrCross.vue";
    import { TradeSearchParameters } from "@/model/TradeSearchParameters";
    import { ITrade } from "@/model/Trade";
    import { BuyerSupplierTrade } from "@/model/BuyerSupplierTrade";
import { debug } from "console";

    @Component({
        components: { ApiButton, TickOrCross, MultiSelectList }
    })
    export default class Region extends Vue {

        async mounted() {
            await Promise.all([
                this.loadSiteList(),
                this.loadRegionList(),
                this.load()
            ]);
        }

        //
        // -- properties
        //

        @Prop({ default: 0 })
        private regionID!: number;

        @Prop({ default: 0 })
        private supplierID!: number;

        @Prop({ required: true })
        private buyerSupplierList!: Array<BuyerSupplier>;

        @Prop({ required: true })
        private buyerList!: Array<ILookupItem>;

        private isWaiting: boolean = true;

        private readonly buyerSupplier = new BuyerSupplier();
        private readonly regionSupplier = new RegionSupplier();
        private selectedTradeIDs: Array<number> = [];

        private get buyerOptions(): Array<ILookupItem> { 
            const buyerIDs = this.buyerSupplierList.map(bs => bs.buyerID) as Array<number>;
            const buyersAvailable = this.buyerList.filter(b => buyerIDs.indexOf(+b.id) === -1);
            const defaultText = buyersAvailable.length === 0 ? "There are no buyers left!" : "Please choose...";
            const defaultItem = new LookupItem({ id: 0, description: defaultText, isArchived: false } as ILookupItem);
            return [defaultItem, ...buyersAvailable];
        }
        private get regionBuyerOptions(): Array<ILookupItem> { 
            const buyerIDs = this.buyerSupplierList.map(bs => bs.buyerID) as Array<number>;
            const buyersAvailable = this.buyerList.filter(b => buyerIDs.indexOf(+b.id) !== -1 );
            const defaultText = buyersAvailable.length === 0 ? "There are no buyers left!" : "Please choose...";
            const defaultItem = new LookupItem({ id: 0, description: defaultText, isArchived: false } as ILookupItem);
            return [defaultItem, ...buyersAvailable];
        }

        private regionList: Array<RegionLookupItem> = [];
        get regionOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu => {
                return lu.id > 0 && !lu.isArchived && lu.buyerID === this.regionSupplier.buyerID &&
                this.regionSupplierList.filter(rs => rs.buyerID === this.regionSupplier.buyerID && rs.regionID === lu.id).length === 0
            });
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "No regions for selected buyer", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Please choose...", isArchived: false }), ...filtered];
        }

        regionSupplierList: Array<RegionSupplier> = [];

        private siteList: Array<ISite> = [];
        get siteOptions(): Array<ISite> { 
            return this.siteList.filter(s => s.id > 0 && !utils.hasDateValue(s.deleted) && s.regionID === this.regionSupplier.regionID);
        }
        
        get buyerDialogueTitle(): string {
            if (!this.buyerSupplier) return "- - -";
            return this.buyerSupplier.isNew
                ? "New Buyer Relationship"
                : "Edit Buyer Relationship";
        }

        get regionDialogueTitle(): string {
            if (!this.regionSupplier) return "- - -";
            return this.buyerSupplier.isNew
                ? "New Region Relationship"
                : "Edit Region Relationship";
        }

        private get canDeleteBuyerSupplier() {
            return !this.buyerSupplier.isNew && !utils.hasDateValue(this.buyerSupplier.deleted);
        }

        private get canDeleteRegionSupplier() {
            return !this.regionSupplier.isNew && !utils.hasDateValue(this.regionSupplier.deleted);
        }

        private get saveBuyerButtonText() {
            return utils.hasDateValue(this.buyerSupplier.deleted) ? "Save Buyer Relationship and Un-archive" : "Save Buyer Relationship";
        }

        private get saveRegionButtonText() {
            return utils.hasDateValue(this.regionSupplier.deleted) ? "Save Region Relationship and Un-archive" : "Save Region Relationship";
        }
        
        private get buyerDescription(): string {
            if (!this.buyerSupplier) return "- - -";
            return utils.lookupDescription(this.buyerSupplier.buyerID, this.buyerList);
        }

        private get tradesList(): Array<LookupItem> {
            return this.buyerSupplier.trades.map(trd => new LookupItem({
                id: trd.tradeID,
                description: trd.description,
                isArchived: false
            }));
        }

        sitesHtml(regionSupplier: RegionSupplier) {
            if(regionSupplier.siteIDs.length === 0) return "";
            let html = "";
            for(const siteID of regionSupplier.siteIDs) {
                const site = this.siteList.find(s => s.id === siteID);
                const x = site ? site.description : "- - -";
                if(html) html += "<br>"
                html += x;
            }
            return html;
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged() {
            this.load();
        }

        @Watch("buyerSupplier.buyerID")
        onBuyerIdChanged() {
            // use nextTick to give extra time for isNew to be set - just in case (???)
            this.$nextTick(() => {
                if(this.buyerSupplier.isNew){
                    this.loadModulesForBuyer(); 
                    this.loadTradesForBuyer();
                }
            });
        }

        @Watch("buyerSupplier.isActive")
        onRelationshipActiveChanged() {
            this.buyerSupplier.doNotChase = this.buyerSupplier.isDormant;
        }

        //
        // -- methods
        //

        async loadSiteList() {
            const parms = new SiteSearchParameters();
            //parms.regionID = this.regionSupplier.regionID;
            // load ALL sites and filter them here (might have to revisit this if there are 1000s of sites)
            this.siteList = await apiClient.post("/api/site/search", parms);
        }      

        async load() {
            this.$v.$reset();
            this.buyerSupplier.modules = [];
            utils.resetObject(this.buyerSupplier);
            utils.resetObject(this.regionSupplier);
            this.regionSupplierList = [];
            await this.refreshBuyerSuppliers();
            await this.refreshRegionSuppliers();
        }

        // for new buyer-supplier, you can change the buyer (drop-down) so need to load modules each time - see watch
        private async loadModulesForBuyer() {
            this.buyerSupplier.modules = [];
            const params = new ModuleSearchParameters();
            params.buyerID = this.buyerSupplier.buyerID;
            const response = await apiClient.post("api/module/search", params);
            const modules: Array<IModule> = response.list;
            this.buyerSupplier.modules = modules.map<BuyerSupplierModule>(m => new BuyerSupplierModule({ 
                moduleID: m.id,
                description: m.description,
                isMandatory: m.isMandatory,
                isSelected: m.isMandatory // select mandatory by default
            }));
        }

        private async loadTradesForBuyer() {
            this.buyerSupplier.trades = [];
            const params = new TradeSearchParameters();
            params.buyerID = this.buyerSupplier.buyerID;
            const response = await apiClient.post("api/trade/search", params);
            const trades: Array<ITrade> = response.list;
            this.buyerSupplier.trades = trades.map<BuyerSupplierTrade>(t => new BuyerSupplierTrade({
                tradeID: t.id,
                description: t.description,
                isSelected: false
            }));
        }

        private async loadRegionList() {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        private async refreshBuyerSuppliers() {
            this.buyerSupplier.modules = [];
            utils.resetObject(this.buyerSupplier);
            this.$emit("fetchBuyers"); // update tab count badge in parent
        }

        private async refreshRegionSuppliers() {
            utils.resetObject(this.regionSupplier);
            const parameters = new RegionSupplierSearchParameters();
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;
            this.isWaiting = true;
            const responseData = await apiClient.post("api/regionSupplier/search", parameters);
            this.regionSupplierList = responseData.list.map((r: IRegionSupplier) => new RegionSupplier(r));
            this.$emit("regionCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }

        private async editBuyer(buyerData: IBuyerSupplier) {
            this.buyerSupplier.isNew = false; 
            this.buyerSupplier.update(buyerData);

            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("buyerDialogue");
            // refresh from server
            const serverBuyerData: IBuyerSupplier = await apiClient.get(`api/buyerSupplier/Load?supplierID=${buyerData.supplierID}&buyerID=${buyerData.buyerID}`);

            // tick mandatory modules by default
            for(const module of serverBuyerData.modules) {
                if(module.isMandatory) module.isSelected = true;
            }
            this.buyerSupplier.update(serverBuyerData); 
            this.selectedTradeIDs = this.buyerSupplier.trades
                .filter(trd => trd.isSelected)
                .map(trd => trd.tradeID);
        }

        private async editRegion(regionData: IRegionSupplier) {
            this.regionSupplier.update(regionData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("regionDialogue");
            // refresh from server
            const serverRegionData = await apiClient.get(`api/regionSupplier/Load?supplierID=${regionData.supplierID}&regionID=${regionData.regionID}`);
            this.regionSupplier.update(serverRegionData);
            this.regionSupplier.isNew = !this.regionSupplier.regionID;
        }

        editNewBuyer() {
            this.buyerSupplier.modules = [];
            utils.resetObject(this.buyerSupplier);
            this.buyerSupplier.isNew = true;
            this.$v.$reset();
            this.buyerSupplier.supplierID = this.supplierID;
            this.$bvModal.show("buyerDialogue");
        }

        editNewRegion() {
            utils.resetObject(this.regionSupplier);
            this.regionSupplier.isNew = true;
            this.$v.$reset();
            this.regionSupplier.supplierID = this.supplierID;
            this.$bvModal.show("regionDialogue");
        }

        cancelBuyer() {
            this.$bvModal.hide("buyerDialogue");
            this.buyerSupplier.modules = [];
            utils.resetObject(this.buyerSupplier);
        }

        cancelRegion() {
            this.$bvModal.hide("regionDialogue");
            utils.resetObject(this.regionSupplier);
        }

        async saveBuyer(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.buyerSupplier.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }

            this.buyerSupplier.trades.forEach(trd => trd.isSelected = this.selectedTradeIDs.includes(trd.tradeID));

            await apiClient.post("/api/buyerSupplier/save", this.buyerSupplier, event);
            this.buyerSupplier.isNew = false;
            toastr.success("Saved");           
            this.$bvModal.hide("buyerDialogue");
            await this.refreshBuyerSuppliers();
        }

        async saveRegion(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.regionSupplier.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            await apiClient.post("/api/regionSupplier/save", this.regionSupplier, event);

            toastr.success("Saved");           
            this.$bvModal.hide("regionDialogue");
            await this.refreshRegionSuppliers();
        }

        async deleteBuyer(event: Event) {
            if (this.buyerSupplier.isNew) return;

            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete '" + this.buyerSupplier.description + "'? In doing so any buyer regions and sites in ‘Region Relationships’ that are associated with the supplier will also be deleted", {
                title: "Delete Buyer",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/buyerSupplier/delete", this.buyerSupplier, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("buyerDialogue");
            await this.refreshBuyerSuppliers();
            await this.refreshRegionSuppliers();
        }

        async deleteRegion(event: Event) {
            if (this.regionSupplier.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.regionSupplier.description + "'?", {
                title: "Delete Region",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/regionSupplier/delete", this.regionSupplier, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("regionDialogue");
            await this.refreshRegionSuppliers();
        }

        @Validations()
        validations() {
            const validations = {
                buyerSupplier: {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                regionSupplier: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            //validations.buyerSupplier.description = { required };
            validations.buyerSupplier.buyerID = { isNonZero: (value: number) => +value > 0 };

            //validations.regionSupplier.description = { required };
            validations.regionSupplier.regionID = { isNonZero: (value: number) => +value > 0 };

            return validations;
        }
    }
