import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { IBuyerSupplierModule, BuyerSupplierModule} from "@/model/BuyerSupplierModule";
import { BuyerSupplierTrade, IBuyerSupplierTrade } from "./BuyerSupplierTrade";

export interface IBuyerSupplier {
    buyerID: number;
    supplierID: number;
    description: string;
    doNotChase: boolean;
    isDormant: boolean;
    doesBuyerPay: boolean;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    modules: Array<IBuyerSupplierModule>;
    trades: Array<IBuyerSupplierTrade>;
}

export class BuyerSupplier implements IBuyerSupplier {

    constructor(data?: IBuyerSupplier) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IBuyerSupplier): void {
        mapData(data, { 
            root: () => this,
            modules: () => new BuyerSupplierModule(),
            trades: () => new BuyerSupplierTrade()
        });
    }

    buyerID: number = 0;
    supplierID: number = 0;
    description: string = "";
    doNotChase: boolean = false;
    isDormant: boolean = false;
    doesBuyerPay: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    get isActive(): boolean {
        return !this.isDormant;
    }
    set isActive(isActive: boolean) {
        this.isDormant = !isActive;
    }

    get shouldChase(): boolean {
        return !this.doNotChase;
    }
    set shouldChase(shouldChase: boolean) {
        this.doNotChase = !shouldChase;
    }

    modules: Array<BuyerSupplierModule> = [];
    trades: Array<BuyerSupplierTrade> = [];

    // Note - isNew needs to be set 'manually' 'cos it's not implicit from ID
    isNew: boolean = false;

    get tradeDescription(): string {
        const trades = this.trades.filter(trd => trd.isSelected);
        return trades.length == 0 ? "None assigned" : trades.map(trd => trd.description).join(", ");
    }
    
}
